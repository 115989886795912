<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智能外呼机器人</div>
          <div class="desc">以语音识别、自然语言理解和语音交互等人工智能技术为基础，用拟人化的方式与用户进行实时语音交互，用于回访、催缴、满意度调查等场景，大幅降低人工外呼压力。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
        <div class="right bg-right"></div>
      </div>
    </div>

    <!-- 产品功能 -->
    <div class="section bg-white func">
      <div class="title">产品功能</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">可视化话术流程设计</div>
              <div class="desc">支持可视化流程配置，界面直观展示流程走向，可快速添加后续节点等便捷操作，同时支持根据配置话术识别用户意图解析语义，知识点可直接从第三方知识库中同步，无须重复创建节约时间。</div>
            </div>
            <img src="@/assets/outbound/fun-1.png" style="width: 600px;height: 514px" />
          </div>
          <div class="item">
            <img src="@/assets/outbound/fun-2.png" style="width: 600px;height: 514px" />
            <div class="text-info" style="width: 466px;">
              <div class="title">完备的外呼任务配置</div>
              <div class="desc">支持配置电话路数、显示号码等任务基本信息，同时可根据话术流程上传或追加外呼名单，并且支持自定义外呼周期，设置重呼策略，满足更多业务场景需求。</div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 417px;">
              <div class="title">灵活的呼叫策略</div>
              <div class="desc">可根据不同的话术类别设置不同的呼叫策略，包括黑名单、白名单、呼叫频率、禁呼时间等，提高智能外呼机器人的灵活性和智能性。</div>
            </div>
            <img src="@/assets/outbound/fun-3.png" style="width: 716px;height: 539px" />
          </div>
          <div class="item">
            <img src="@/assets/outbound/fun-4.png" style="width: 584px;height: 428px" />
            <div class="text-info" style="width: 466px;">
              <div class="title">多维可视化数据分析</div>
              <div class="desc">任务结果分析，支持对外呼任务的结果进行分析，辅助运营决策； 可进行人机交互音频测听，针对外呼任务实时监控，可进行有效干预；针对外呼任务可查看外呼任务进度及外呼结果，辅助外呼策略调整及业务运营。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="section advantage">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in advantageList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
        <div class="bg-adv"></div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in sceneList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">
                <div class="item" v-for="(info, infoIndex) in item.infoList" :key="infoIndex">
                  {{info}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="bg-case"></div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advantageList: [
        {
          name: '行业知识应用',
          content: '话术流程可结合行业智能知识库配置通用FAQ知识，提升外呼机器人智能化水平',
          icon: 'icon-adv-1.png'
        },
        {
          name: '灵活的语音交互',
          content: '单轮回答、多轮对话交互效果自然流畅，支持灵活打断；实现场景级、节点级交互配置，深入业务流程实现复杂话术流程配置',
          icon: 'icon-adv-2.png'
        },
        {
          name: '话术流程快速搭建',
          content: '通过简单易上手的画布式对话编辑器，快速进行话术流程设计，提高工作效率',
          icon: 'icon-adv-3.png'
        },
        {
          name: '多维数据分析',
          content: '对话详情及会话任务的数据统计，支持流程级节点级查看会话内容，挖掘数据价值',
          icon: 'icon-adv-4.png'
        }
      ],
      sceneList: [
        {
          name: '电销',
          content: '各类货款催收业务的电话录音质检， 如信用卡还款催收、经营货/信用货还 款催收、消费金融还款催收等投诉风 险管控；',
          icon: 'icon-scene-1.png',
          infoList: ['新办卡、工银信使、三方绑卡', '理财到期客户产品承接', '定期到期客户产品承接', '信用卡分期']
        },
        {
          name: '催收和通知',
          content: '客户关系维护和营销电话质检，如客 户线索和商机营销电话的话术和流程 质检，客户投诉分析和客户购买意向 自动登记等；',
          icon: 'icon-scene-2.png',
          infoList: ['MO智能化提醒', 'M1策略化催收', '积分兑换', '生日有礼活动通知']
        },
        {
          name: '回访和核验',
          content: '如在线保险报案受理、事件结案回访 在线服务订单办理等业务场景进行话 术质检、流程质检、客户信息自动收 集记录和信息校验；',
          icon: 'icon-scene-3.png',
          infoList: ['身份核验', '信息补全', '语音对账', '实时反欺诈']
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/outbound/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
